<template>


    <div>
        <v-form ref="form" v-model="valid">

            <v-card height="auto" flat>

                <!-- <v-card-title class="headline grey lighten-2" primary-title
                style="	font-family: 'droid_bold' !important;    background-color: #435c6f!important;color:#fff">
                <span v-if="item_ID==0">اضافة مركز</span>
                <span v-else>تعديل مركز</span>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="cancel()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title> -->


                <v-container style="padding:20px" class="additem_con">





                <!-- <v-stepper v-model="e1" v-if="steeps"> -->
                <v-stepper v-model="e1" >
                    <!--Star Steper Header-->
                    <!-- <v-stepper-header class="hidden-md-and-down"   v-if="owner.address.length==0">

                        <v-stepper-step :rules="[() =>this.er1]" :complete="e1 > 1" step="1">المعلومات
                            الاساسيه
                            <small>{{this.errors_primery[0]}}</small>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :rules="[() =>this.er_address]" :complete="e1 > 2" step="2"
                            v-if="owner.address.length==0">معلومات
                            الموقع
                            <small>{{this.errors_address[0]}}</small>
                        </v-stepper-step>

                        <v-divider v-if="owner.address.length==0"></v-divider> -->
<!-- 
                        <v-stepper-step :rules="[() =>this.er_date]" :complete="e1 > 3" :editable="false" step="3">
                            معلومات
                            الوقت
                            <small>{{this.errors_date[0]}}</small>
                        </v-stepper-step>
                        <v-divider></v-divider> -->


                        <!-- <v-stepper-step :complete="e1 > 4" step="4" :editable="false"
                                :rules="[() =>this.er_payment]">
                                معلومات الدفع
                                <small>{{this.errors_payment[0]}}</small>

                            </v-stepper-step>
                            <v-divider></v-divider> -->
                        <!-- 
                            <v-stepper-step :complete="e1 > 5" step="5" :editable="false">
                                ميزات اضافيه
                        

                            </v-stepper-step>

                            <v-divider></v-divider> -->
                        <!-- <v-stepper-step step="6"> الصور</v-stepper-step> -->
                    <!-- </v-stepper-header> -->
                    <!--End Steper Header-->



                    <v-stepper-items>

                        <!--Primery Info-->
                        <v-stepper-content step="1">
                            <v-card class="mb-12" color="#cac0c057" flat pl-5 height="auto" style="padding-right:1%">

                                <v-form ref="valid_Primery" v-model="valid_Primery">
                                    <v-layout row wrap pr-2 pl-2>

                                        <v-flex pr-3 xs12 md4 sm4>
                                            <v-text-field background-color="#fff" height="50" style=""
                                                placeholder="اسم الخدمه" v-model="editedItem.item_name"
                                                :rules="nameRules" required></v-text-field>
                                        </v-flex>


                                        <!-- 
                                        </v-layout> -->

                                        <v-flex pr-3 pl3 xs12 md4 sm4 v-if="always_hidden">
                                            <v-select background-color="#ffffff" height="50" class="my-2"
                                                :items="category" item-value="id" item-text="name"
                                                v-model="editedItem.sub_category.category_id" type="hidden"
                                                placeholder=" القسم" required>
                                            </v-select>
                                        </v-flex>
                                        <!-- <v-layout row wrap pr-2 pl-2>
 -->



                                        <v-flex pr-3 pl3 xs12 md4 sm4>
                                            <v-select :rules="nameRules" segmented class="my-2" :items="subcategory"
                                                item-value="id" item-text="name" background-color="#ffffff" height="50" 
                                                v-model="editedItem.sub_category.id" @change="getLatCategory()"
                                                placeholder="  القسم" required>
                                            </v-select>
                                        </v-flex>


                                        <v-flex pr-3 pl3 xs12 md4 sm4 v-if="lastscategory">
                                            <v-select segmented class="my-2" :items="lastcategory" item-value="id"
                                                item-text="name" background-color="#ffffff" height="50" 
                                                v-model="editedItem.last_category_id" placeholder=" التصنيف الفرعي"
                                                required></v-select>
                                        </v-flex>






                                    </v-layout>




                                    <v-layout row wrap pr-4>

                                        <v-flex xs10 sm3 md4>

                                            <v-text-field background-color="#fff" height="50" placeholder=" سعر الحجز"
                                                data-vv-name="name" v-model="editedItem.price.price_value"
                                                :rules="numberRul" required>
                                            </v-text-field>

                                        </v-flex>

                                        <!-- 
                                               <v-flex xs2 md1 sm1 pr-1 pt-5>
                                                <span style="font-weight:bold" v-if="editedItem.price.is_dollars==1">
                                                    دينار
                                                </span>

                                                <span style="font-weight:bold"
                                                    v-else-if="editedItem.price.is_dollars==2">
                                                    دولار
                                                </span>

                                            </v-flex> -->




                                        <!-- <v-flex xs3 md1 sm1 pt-4>نوع العمله</v-flex> -->
                                        <v-flex xs9 md5 sm5 pt-3>

                                            <v-radio-group :rules="is_dollarsRul" required
                                                v-model="editedItem.price.is_dollars">
                                                <v-layout row wrap>

                                                    <v-flex xs4 md1 sm1>
                                                        <v-radio label="دينار" :value="1"></v-radio>
                                                    </v-flex>
                                                    <v-flex xs4 md1 sm1 pr-5>
                                                        <v-radio @change="DollarsDialog=true" label="دولار" :value="2">
                                                        </v-radio>
                                                    </v-flex>




                                                </v-layout>



                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>








                                    <!-- <v-layout row wrap pl-2 pr-4>

                                            <span>طريقه الدفع :</span>
                                        </v-layout> -->
                                            <v-layout row wrap pl-2 pr-4>

                                        <span style="font-weight:bold">طريقه الدفع :</span>
                                    </v-layout>
                                    <v-radio-group @change="tr()" :rules="paymentRul" required   style="    width: 65%;padding-right: 10px;padding-left: 45px;padding-top: 10px;"
                                        v-model="editedItem.price.payment_when_receiving">
                                        <v-layout row wrap pl-2 pr-4 style="background-color:#fff">

                                           


                                            <v-flex xs4 pt-3 pb-3>
                                                <v-radio label="الحجز بدون بطاقة دفع" :value="3"></v-radio>
                                            </v-flex>
                                            <v-flex xs4 pt-3 pb-3>
                                                <v-radio label=" الدفع عن طريق بطاقه دفع" :value="1"></v-radio>
                                            </v-flex>
                                            <v-flex xs4 pt-3 pb-3>
                                                <v-radio label=" الدفع عن طريق بطاقه او بدون بطاقه" :value="2">
                                                </v-radio>
                                            </v-flex>

                                            

                                        <!-- </v-layout> -->
                                   

                                    <!-- <v-layout pl-2 row wrap pr-5
                                        v-if="editedItem.price.payment_when_receiving==1 || editedItem.price.payment_when_receiving==2 "> -->

                                        <v-flex xs4 v-if="editedItem.price.payment_when_receiving==1 || editedItem.price.payment_when_receiving==2 ">

                                        </v-flex>


                                        <v-flex xs4 v-if="editedItem.price.payment_when_receiving==1 || editedItem.price.payment_when_receiving==2 ">
                                            <v-checkbox v-model="editedItem.price.is_tasdid_payment"
                                                label="الدفع عن طريق فاتوره تسديد">
                                            </v-checkbox>
                                        </v-flex>

                                        <v-flex xs4 v-if="editedItem.price.payment_when_receiving==1 || editedItem.price.payment_when_receiving==2 ">
                                            <v-checkbox v-model="editedItem.price.is_zaincash_payment"
                                                label="الدفع عن طريق زين كاش"></v-checkbox>
                                        </v-flex>

                                          <v-flex xs12 md4 sm4 pa-1>
                                                <v-checkbox v-model="editedItem.price.free_cancellation"
                                                    label="امكانية الغاء الحجز" required></v-checkbox>
                                            </v-flex>



                                       <v-flex xs12 md3 sm3 pa-1  v-if="editedItem.price.payment_when_receiving==1 || editedItem.price.payment_when_receiving==2 " pl-4>
                                                <v-text-field prepend-inner-icon="fas fa-percent"
                                                    background-color="#fff" class="deucation_txfield" height="40"
                                                    placeholder="  نسبة الاستقطاع" data-vv-name="name"
                                                    :disabled="!editedItem.price.free_cancellation"
                                                    :readonly="!editedItem.price.free_cancellation"
                                                    v-model="editedItem.price.cancellation_deduction_ratio"
                                                    :rules="numberRul1">
                                                </v-text-field>
                                            </v-flex>


                                    </v-layout>

 </v-radio-group>

                                    <v-layout row wrap pl-2 pr-4>
                                
                                    </v-layout>


                                    <v-layout row wrap pl-2 pr-4   >

                                        <span  style="font-weight:bold">اختر العنوان :</span>
                                    </v-layout>


                                    <div v-if="owner.address.length>0" style="padding-top: 5px;padding-right:10px">

                                        <v-layout row wrap pa-3 xs12 pr-4>




                                            <v-simple-table style="    background-color: #fff;">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>

                                                            <th class="text-left">
                                                                #
                                                            </th>

                                                            <th class="text-left">
                                                                المحافظة
                                                            </th>
                                                            <th class="text-left">
                                                                الوصف
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                   
                                                        <tr xs5 md3 sm3 v-for="address in owner.address"
                                                            :key="address.id">
                                                            <td>
                                                                <v-checkbox v-model="xc" 
                                                                    :value-comparator="comparator"
                                                                    :value="address.id">
                                                                        
                                                                       

                                                                </v-checkbox>
                                                            </td>
                                                            <td>{{ address.province.province_name }}</td>
                                                            <td>{{ address.address_descraption }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            <v-flex xs5 md3 sm3 v-for="address in owner.address" :key="address.id">

                                                <!-- <v-checkbox v-model="editedItem.address_id"  @change="rules_check()"
                                                       
                                                        hide-details
                                                        :label="address.province.province_name+'_'+address.address_descraption"
                                                        :value="parseInt(address.id)"></v-checkbox> -->


                                            </v-flex>





                                            <v-flex xs12> <span style="color:red"> {{address_error}}</span></v-flex>




                                        </v-layout>
                                    </div>


                                    <div v-else>
                                        <!-- <v-card class="add_card add_card mb-12" color="#cac0c057" height="484px" flat>
                                <v-form ref="valid_address" v-model="valid_address"> -->
                                    <v-layout row wrap pr-2 pl-2>


                                        <v-flex pr-3 xs12 md4 sm4>
                                            <v-select background-color="#fff" height="50" item-value="id" :rules="provRul"
                                                item-text="province_name" :items="province" placeholder=" المحافظة"
                                                target="#dropdown-example" v-model="editedItem.Address.province.id"
                                                required></v-select>
                                        </v-flex>

                                        <v-flex pr-3 xs8>
                                            <v-text-field background-color="#fff" placeholder=" الوصف " :rules="addDescRul"   height="50"
                                                v-model="editedItem.Address.address_descraption" required>
                                            </v-text-field>
                                        </v-flex>

                                    </v-layout>
                                    <v-flex xs12 v-if="map">
                                        <GoogleMap @getmyLocation="getlocation" :latt="editedItem.Address.lat"
                                            :long="editedItem.Address.long" @cancel="map_dialog=false" />
                                    </v-flex>
                                    <!-- <v-btn color="primary" @click="step3()">
                                        حفظ
                                    </v-btn>
                                    <v-btn text @click="step1()"> السابق</v-btn> -->
                                <!-- </v-form>
                            </v-card> -->
                                    </div>




                                    <!--Tabel Of Days-->



                                    <v-row style="padding-right: 15px;padding-left: 15px;">

                                        <v-flex pr-3 pl3 xs12 pt-4 pb-4 md6 sm6 style="    position: relative;
    bottom: 13px;">
                                          
                                                <v-radio-group v-model="editedItem.day_to_open[0].every_day" mandatory>
                                                    <v-layout>

                                                        <v-flex xs2 m2 sm2>
                                                            نوع الحجز

                                                        </v-flex>

                                                        <v-flex xs4>
                                                            <v-radio label="حجز موعد فتره زمنيه محدده"
                                                                @change="is_everyy_day=false" :value="0"></v-radio>
                                                        </v-flex>

                                                        <v-flex xs7>
                                                            <v-radio @change="is_everyy_day=true"
                                                                label="  الحجز الاني    ( عدم اختيار فتره موعد عند الحجز مفتوح كل الاسبوع)    "
                                                                :value="1">
                                                            </v-radio>

                                                        </v-flex>


                                                    </v-layout>





                                                </v-radio-group>



                                            </v-flex>


                                        <v-flex pr-3 pt-4 pb-4 pl3 xs12 sm4 md4 style="    position: relative;
    bottom: 13px;">

                                            <v-radio-group required mandatory :rules="StatusRules"
                                                v-model="editedItem.status.id">
                                                <v-layout>

                                                    <v-flex xs3 ms2 sm2>
                                                        حاله الحجز

                                                    </v-flex>

                                                    <v-flex xs3>
                                                        <v-radio label="مغلق" :value="12"></v-radio>
                                                    </v-flex>

                                                    <v-flex xs3>
                                                        <v-radio label="مفتوح" :value="11"></v-radio>

                                                    </v-flex>


                                                </v-layout>





                                            </v-radio-group>


                                        </v-flex>

                                        <v-flex xs12 pr-2 pl-2 style="    position: relative;
    bottom: 23px;">


                                            <v-simple-table v-if="!is_everyy_day" style="width:50%">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-right">اليوم</th>
                                                            <!-- <th class="text-right">من الساعه</th>
                                                            <th class="text-right">الى الساعه</th> -->
                                                            <!-- <th class="text-right">نوع الحجز</th> -->
                                                            <th class="text-right">الحاله</th>

                                                            <th class="text-right"></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="item in editedItem.day_to_open" :key="item">
                                                            <td> {{ getdays(item.work_day)}} </td>

                                                            <!-- <td v-if="item.time_to_open.length">
                                                                {{formatAMPM(item.time_to_open[0].from_time)}}</td>
                                                            <td v-else></td>

                                                            <td v-if="item.time_to_open.length">
                                                                {{formatAMPM(item.time_to_open[0].to_time)}}</td>
                                                            <td v-else></td> -->


                                                            <!-- <td>{{getstatuss(item.reservation_type.id)}}</td> -->
                                                            <td v-if="item.time_to_open.length">
                                                                {{getstatus(item.status['id'])}}</td>
                                                            <td v-else>{{getstatus(item.status['id'])}}</td>

                                                            <td>
                                                                <!-- <v-icon small class="mr-2" @click="editItem(item)">
                                                                        edit
                                                                    </v-icon> -->

                                                                <v-btn small flat color="green" style="color:#fff"
                                                                    @click="editItem(item)">
                                                                    <v-icon small class="mr-2">
                                                                        edit
                                                                    </v-icon>
                                                                    <span>تعديل اوقات العمل</span>
                                                                </v-btn>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>





                                            <v-dialog v-model="DayDialog" width="1200" persistent height="900">




                                                <v-card height="auto" flat>

                                                    <v-layout row wrap="">
                                                        <v-flex xs3 md2 sm2>
                                                            <h3 style="padding-right:35px;padding-top: 20px;"> يوم
                                                                {{ getdays(editeDayOpen.work_day)}} :
                                                            </h3>


                                                        </v-flex>

                                                        <v-flex xs2 md2 sm2>

                                                            <v-form ref="valid_day_open" v-model="valid_day_open">
                                                                <span v-if="editeDayOpen.status">
                                                                    <v-radio-group required :rules="DayStatusRules"
                                                                        v-model="editeDayOpen.status.id">
                                                                        <v-radio @change="checkEmpty()" label="مفتوح"
                                                                            :value="23"></v-radio>
                                                                        <v-radio @change="time_dialog=false"
                                                                            label="مغلق" :value="22"></v-radio>
                                                                    </v-radio-group>
                                                                </span>
                                                            </v-form>



                                                        </v-flex>
                                                        <v-flex xs1 md6 sm6>

                                                        </v-flex>
                                                        <!-- 
                                            <v-flex xs1> -->

                                                        <!-- <v-spacer></v-spacer>
                                                <v-btn icon dark @click="close_day()">
                                                    <v-icon color="red">mdi-close</v-icon>
                                                </v-btn> -->
                                                        <!-- 
                                            </v-flex> -->

                                                        <v-flex xs5 md2 sm2 pt-3>
                                                            <v-btn small dense color="green" dark @click="cancel_day()">
                                                                حفظ التعديلات وخروج

                                                                <!-- <v-icon color="red">mdi-close</v-icon> -->
                                                            </v-btn>
                                                        </v-flex>

                                                    </v-layout>

                                                    <h1 style="padding-right:35px;padding-top:10px;">
                                                        <v-btn @click="time_dialog=true;xx()" color="success"
                                                            style="height: 28px;margin-bottom: 9px;">

                                                            <v-icon>
                                                                fas fa-plus
                                                            </v-icon>

                                                            اضافه فتره
                                                        </v-btn>
                                                    </h1>



                                                    <v-layout row wrap justify="space-around" pr-4>

                                                        <v-simple-table style="width:80%">
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr v-if="editeDayOpen.time_to_open.length">
                                                                        <!-- <th class="text-right">#</th> -->
                                                                        <th class="text-right">من الساعه</th>
                                                                        <th class="text-right">الى الساعه</th>
                                                                        <th class="text-right">نوع الحجز</th>
                                                                        <th class="text-right">عدد الحجوزات</th>
                                                                        <th class="text-right">مده الحجز </th>
                                                                        <th class="text-right">الحاله</th>


                                                                        <th class="text-right"></th>
                                                                    </tr>
                                                                    <tr v-else></tr>


                                                                </thead>

                                                                <tbody>


                                                                    <tr v-for="item in editeDayOpen.time_to_open"
                                                                        :key="item">
                                                                        <!-- <td>{{item.id}} </td> -->
                                                                        <td>{{formatAMPM(item.from_time)}}</td>
                                                                        <td>{{formatAMPM(item.to_time)}}</td>
                                                                        <td>{{item.reservation_type.reservation_type_name}}
                                                                        </td>
                                                                        <td v-if="item.reservation_type.id==3">غير
                                                                            محدود
                                                                        </td>
                                                                        <td v-else>{{item.reservations_number}}</td>
                                                                        <td>{{item.reservation_duration}}</td>
                                                                        <td>{{item.status.status_name_ar}}</td>
                                                                        <td>
                                                                            <v-btn icon
                                                                                @click="time_dialog=true,editedTimeOpen(item)">
                                                                                <v-icon>

                                                                                    edit
                                                                                </v-icon>
                                                                            </v-btn>



                                                                            <v-btn icon @click="DeleteTimesOpen(item)">
                                                                                <v-icon>

                                                                                    fas fa-trash
                                                                                </v-icon>
                                                                            </v-btn>

                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-layout>




                                                    <br>

                                                    <div v-if="time_dialog">

                                                        <v-layout row wrap justify="space-around"
                                                            style="padding-right:15px">








                                                            <!--Add-->
                                                            <v-col xs3 style="" pr-3 v-if="!is_editeTimeOpen">
                                                                <h2 style="color:#000"> من الساعه :</h2>
                                                                <v-time-picker v-model="editeTimeOpen.from_time"
                                                                    :allowed-hours="allowedHour"
                                                                    :max="editeTimeOpen.to_time" locale="ar-iq">
                                                                </v-time-picker>
                                                            </v-col>

                                                            <v-col xs3 style="" pr-3 v-else>
                                                                <h2 style="color:#000"> من الساعه :</h2>
                                                                <v-time-picker v-model="editeTimeOpen.from_time"
                                                                    disabled="hidden" :allowed-hours="allowedHour"
                                                                    :max="editeTimeOpen.to_time" locale="ar-iq">
                                                                </v-time-picker>
                                                            </v-col>





                                                            <!--Add-->
                                                            <v-col xs3 style="text-align:right"
                                                                v-if="!is_editeTimeOpen">
                                                                <h2 style="color:#000">الى الساعه : </h2>
                                                                <v-time-picker :rules="to_timeRules"
                                                                    :min="editeTimeOpen.from_time" locale="ar-iq"
                                                                    :allowed-hours="allowedHour"
                                                                    v-model="editeTimeOpen.to_time" required>
                                                                </v-time-picker>
                                                            </v-col>


                                                            <!--Edite-->
                                                            <v-col xs3 v-else style="text-align:right">
                                                                <h2 style="color:#000">الى الساعه : </h2>
                                                                <v-time-picker :rules="to_timeRules"
                                                                    :min="editeTimeOpen.from_time" disabled="hidden"
                                                                    locale="ar-iq" :allowed-hours="allowedHour"
                                                                    v-model="editeTimeOpen.to_time" required>
                                                                </v-time-picker>
                                                            </v-col>










                                                            <v-col>
                                                                <v-select background-color="#fff" height="50"
                                                                    item-value="id" item-text="status_name_ar"
                                                                    :items="day_status" placeholder="الحاله"
                                                                    target="#dropdown-example" return-object
                                                                    v-model="editeTimeOpen.status" required
                                                                    :rules="TimeStatusRules"></v-select>

                                                                <div v-if="!is_editeTimeOpen">


                                                                    <v-select background-color="#fff" height="50"
                                                                        item-value="id"
                                                                        item-text="reservation_type_name"
                                                                        :items="reservation_type"
                                                                        placeholder="نوع الحجز"
                                                                        target="#dropdown-example" return-object
                                                                        @change="change_reservation_type()"
                                                                        v-model="editeTimeOpen.reservation_type"
                                                                        required :rules="ReservationTypeRules">
                                                                    </v-select>

                                                                    <v-text-field background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==2"
                                                                        placeholder=" اختار العدد " type="number"
                                                                        v-model="editeTimeOpen.reservations_number">
                                                                    </v-text-field>



                                                                    <v-text-field background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==1"
                                                                        placeholder=" اختار عدد دقائق الحجز "
                                                                        type="number"
                                                                        v-model="editeTimeOpen.reservation_duration">
                                                                    </v-text-field>
                                                                </div>







                                                                <!--Edite-->
                                                                <div v-else>


                                                                    <v-select disabled="hidden" background-color="#fff"
                                                                        height="50" item-value="id"
                                                                        item-text="reservation_type_name"
                                                                        :items="reservation_type"
                                                                        placeholder="نوع الحجز"
                                                                        target="#dropdown-example" return-object
                                                                        @change="change_reservation_type()"
                                                                        v-model="editeTimeOpen.reservation_type"
                                                                        required :rules="ReservationTypeRules">
                                                                    </v-select>

                                                                    <v-text-field disabled="hidden"
                                                                        background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==2"
                                                                        placeholder=" اختار العدد " type="number"
                                                                        v-model="editeTimeOpen.reservations_number">
                                                                    </v-text-field>



                                                                    <v-text-field disabled="hidden"
                                                                        background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==1"
                                                                        placeholder=" اختار عدد دقائق الحجز "
                                                                        type="number"
                                                                        v-model="editeTimeOpen.reservation_duration">
                                                                    </v-text-field>
                                                                </div>






                                                            </v-col>
                                                        </v-layout>

                                                        <v-row>

                                                            <v-flex xs1 md9 sm9>

                                                            </v-flex>

                                                            <v-flex xs11 md3 sm3>
                                                                <v-layout row wrap>
                                                                    <v-flex xs4 pr-3>
                                                                        <v-btn color="primary" @click="save_time()"
                                                                            style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;width:100%">

                                                                            حفظ
                                                                        </v-btn>
                                                                    </v-flex>
                                                                    <v-flex xs4 pr-3>
                                                                        <v-btn color="red"
                                                                            @click="time_dialog=false;close_time()"
                                                                            style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;color:#fff;width:100%">

                                                                            الغاء
                                                                        </v-btn>

                                                                    </v-flex>
                                                                </v-layout>
                                                                <br>

                                                            </v-flex>



                                                        </v-row>
                                                    </div>
                                                    <v-layout style="background-color:#fff">
                                                        <v-flex xs9>

                                                        </v-flex>
                                                        <v-flex xs3 pl-4>
                                                            <v-btn color="red" @click="DayDialog=false"
                                                                style="text-align:left;float:left;color:#fff;">

                                                                خروج
                                                            </v-btn>

                                                        </v-flex>
                                                    </v-layout>
                                                </v-card>

                                            </v-dialog>







                                        </v-flex>

                                        <v-flex xs4>

                                        </v-flex>
                                    </v-row>


 <v-layout>
                                <v-flex pa-3 xs12>
                                    <v-textarea background-color="#fff" placeholder=" الوصف "
                                        v-model="editedItem.item_description" :rules="descriptRules" required>
                                    </v-textarea>
                                </v-flex>



                            </v-layout>



                                </v-form>




                                <!-- <v-btn color="primary" @click="step4()">
                                        التالي
                                    </v-btn> -->

                                <!-- <v-btn @click="submit" color="success" style="margin-left:10px">
                                    <span v-show="!loading">حفظ</span>
                                    <v-progress-circular indeterminate color="white" v-show="loading">
                                    </v-progress-circular>
                                </v-btn>

                                <v-btn text v-if="editedItem.address_id==null" @click="step2()"> السابق</v-btn>
                                <v-btn text v-else @click="step1()"> السابق</v-btn> -->

     <v-row pr-4 style="padding-right: 30px;">

                                                            <!-- <v-flex xs1 md9 sm9>

                                                            </v-flex> -->

                                                            <v-flex xs11 md3 sm3>
                                                                <v-layout row wrap>
                                                                    <v-flex xs4 pr-3>
                                                                        <v-btn color="primary" @click="step2()"
                                                                            style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;width:100%">

                                                                            حفظ
                                                                        </v-btn>
                                                                    </v-flex>
                                                                    <v-flex xs4 pr-3>
                                                                        <v-btn color="red"
                                                                            @click="time_dialog=false;close_time()"
                                                                            style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;color:#fff;width:100%">

                                                                            الغاء
                                                                        </v-btn>

                                                                    </v-flex>
                                                                </v-layout>
                                                                <br>

                                                            </v-flex>



                                                        </v-row>
                            </v-card>




                           



                        

                            <!-- <v-btn text>Cancel</v-btn> -->
                        </v-stepper-content>
                        <!--End Primery Info-->









                        <!--Location Info-->
                        <v-stepper-content step="2" v-if="owner.address.length==0">

                            <v-card class="add_card add_card mb-12" color="#cac0c057" height="484px" flat>
                                <v-form ref="valid_address" v-model="valid_address">
                                    <v-layout row wrap pr-2 pl-2>


                                        <v-flex pr-3 xs12 md4 sm4>
                                            <v-select background-color="#fff" height="50" item-value="id"
                                                item-text="province_name" :items="province" placeholder=" المحافظة"
                                                target="#dropdown-example" v-model="editedItem.Address.province.id"
                                                required></v-select>
                                        </v-flex>

                                        <v-flex pr-3 xs8>
                                            <v-text-field background-color="#fff" placeholder=" الوصف " height="50"
                                                v-model="editedItem.Address.address_descraption" required>
                                            </v-text-field>
                                        </v-flex>

                                    </v-layout>
                                    <v-flex xs12 v-if="map">
                                        <GoogleMap @getmyLocation="getlocation" :latt="editedItem.Address.lat"
                                            :long="editedItem.Address.long" @cancel="map_dialog=false" />
                                    </v-flex>
                                    <v-btn color="primary" @click="step3()">
                                        حفظ
                                    </v-btn>
                                    <v-btn text @click="step1()"> السابق</v-btn>
                                </v-form>
                            </v-card>


                        </v-stepper-content>
                        <!--End Location Info-->


                        <!--Date Info-->
                        <v-stepper-content step="3">
                            <v-card class="add_card mb-12" color="#cac0c057" height="443x" flat>





                                <v-form ref="form_day" v-model="valid_day" style="">
                                    <!--Tabel Of Days-->



                                    <v-row style="padding-right: 15px;padding-left: 15px;">



                                        <v-flex pr-3 pt-4 pb-4 pl3 xs12 sm4 md4 style="    position: relative;
    bottom: 13px;">

                                            <v-radio-group required mandatory :rules="StatusRules"
                                                v-model="editedItem.status.id">
                                                <v-layout>

                                                    <v-flex xs3 ms2 sm2>
                                                        حاله الحجز

                                                    </v-flex>

                                                    <v-flex xs3>
                                                        <v-radio label="مغلق" :value="12"></v-radio>
                                                    </v-flex>

                                                    <v-flex xs3>
                                                        <v-radio label="مفتوح" :value="11"></v-radio>

                                                    </v-flex>


                                                </v-layout>





                                            </v-radio-group>


                                        </v-flex>

                                        <v-flex xs12 pr-2 pl-2 style="    position: relative;
    bottom: 23px;">


                                            <v-simple-table v-if="!is_everyy_day">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-right">اليوم</th>
                            
                                                            <th class="text-right">الحاله</th>

                                                            <th class="text-right"></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="item in editedItem.day_to_open" :key="item">
                                                            <td> {{ getdays(item.work_day)}} </td>

                                                     
                                                            <td v-if="item.time_to_open.length">
                                                                {{getstatus(item.status['id'])}}</td>
                                                            <td v-else>{{getstatus(item.status['id'])}}</td>

                                                            <td>
                                                        

                                                                <v-btn small flat color="green" style="color:#fff"
                                                                    @click="editItem(item)">
                                                                    <v-icon small class="mr-2">
                                                                        edit
                                                                    </v-icon>
                                                                    <span>تعديل اوقات العمل</span>
                                                                </v-btn>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>





                                            <v-dialog v-model="DayDialog" width="1200" persistent height="900">




                                                <v-card height="auto" flat>

                                                    <v-layout row wrap="">
                                                        <v-flex xs3 md2 sm2>
                                                            <h3 style="padding-right:35px;padding-top: 20px;"> يوم
                                                                {{ getdays(editeDayOpen.work_day)}} :
                                                            </h3>


                                                        </v-flex>

                                                        <v-flex xs2 md2 sm2>

                                                            <v-form ref="valid_day_open" v-model="valid_day_open">
                                                                <span v-if="editeDayOpen.status">
                                                                    <v-radio-group required :rules="DayStatusRules"
                                                                        v-model="editeDayOpen.status.id">
                                                                        <v-radio @change="checkEmpty()" label="مفتوح"
                                                                            :value="23"></v-radio>
                                                                        <v-radio @change="time_dialog=false"
                                                                            label="مغلق" :value="22"></v-radio>
                                                                    </v-radio-group>
                                                                </span>
                                                            </v-form>



                                                        </v-flex>
                                                        <v-flex xs1 md6 sm6>

                                                        </v-flex>
                                                        <!-- 
                                            <v-flex xs1> -->

                                                        <!-- <v-spacer></v-spacer>
                                                <v-btn icon dark @click="close_day()">
                                                    <v-icon color="red">mdi-close</v-icon>
                                                </v-btn> -->
                                                        <!-- 
                                            </v-flex> -->

                                                        <v-flex xs5 md2 sm2 pt-3>
                                                            <v-btn small dense color="green" dark @click="cancel_day()">
                                                                حفظ التعديلات وخروج

                                                                <!-- <v-icon color="red">mdi-close</v-icon> -->
                                                            </v-btn>
                                                        </v-flex>

                                                    </v-layout>

                                                    <h1 style="padding-right:35px;padding-top:10px;">
                                                        <v-btn @click="time_dialog=true;xx()" color="success"
                                                            style="height: 28px;margin-bottom: 9px;">

                                                            <v-icon>
                                                                fas fa-plus
                                                            </v-icon>

                                                            اضافه فتره
                                                        </v-btn>
                                                    </h1>



                                                    <v-layout row wrap justify="space-around" pr-4>

                                                        <v-simple-table style="width:80%">
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr v-if="editeDayOpen.time_to_open.length">
                                                                        <!-- <th class="text-right">#</th> -->
                                                                        <th class="text-right">من الساعه</th>
                                                                        <th class="text-right">الى الساعه</th>
                                                                        <th class="text-right">نوع الحجز</th>
                                                                        <th class="text-right">عدد الحجوزات</th>
                                                                        <th class="text-right">مده الحجز </th>
                                                                        <th class="text-right">الحاله</th>


                                                                        <th class="text-right"></th>
                                                                    </tr>
                                                                    <tr v-else></tr>


                                                                </thead>

                                                                <tbody>


                                                                    <tr v-for="item in editeDayOpen.time_to_open"
                                                                        :key="item">
                                                                        <!-- <td>{{item.id}} </td> -->
                                                                        <td>{{formatAMPM(item.from_time)}}</td>
                                                                        <td>{{formatAMPM(item.to_time)}}</td>
                                                                        <td>{{item.reservation_type.reservation_type_name}}
                                                                        </td>
                                                                        <td v-if="item.reservation_type.id==3">غير
                                                                            محدود
                                                                        </td>
                                                                        <td v-else>{{item.reservations_number}}</td>
                                                                        <td>{{item.reservation_duration}}</td>
                                                                        <td>{{item.status.status_name_ar}}</td>
                                                                        <td>
                                                                            <v-btn icon
                                                                                @click="time_dialog=true,editedTimeOpen(item)">
                                                                                <v-icon>

                                                                                    edit
                                                                                </v-icon>
                                                                            </v-btn>



                                                                            <v-btn icon @click="DeleteTimesOpen(item)">
                                                                                <v-icon>

                                                                                    fas fa-trash
                                                                                </v-icon>
                                                                            </v-btn>

                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-layout>




                                                    <br>

                                                    <div v-if="time_dialog">

                                                        <v-layout row wrap justify="space-around"
                                                            style="padding-right:15px">








                                                            <!--Add-->
                                                            <v-col xs3 style="" pr-3 v-if="!is_editeTimeOpen">
                                                                <h2 style="color:#000"> من الساعه :</h2>
                                                                <v-time-picker v-model="editeTimeOpen.from_time"
                                                                    :allowed-hours="allowedHour"
                                                                    :max="editeTimeOpen.to_time" locale="ar-iq">
                                                                </v-time-picker>
                                                            </v-col>

                                                            <v-col xs3 style="" pr-3 v-else>
                                                                <h2 style="color:#000"> من الساعه :</h2>
                                                                <v-time-picker v-model="editeTimeOpen.from_time"
                                                                    disabled="hidden" :allowed-hours="allowedHour"
                                                                    :max="editeTimeOpen.to_time" locale="ar-iq">
                                                                </v-time-picker>
                                                            </v-col>





                                                            <!--Add-->
                                                            <v-col xs3 style="text-align:right"
                                                                v-if="!is_editeTimeOpen">
                                                                <h2 style="color:#000">الى الساعه : </h2>
                                                                <v-time-picker :rules="to_timeRules"
                                                                    :min="editeTimeOpen.from_time" locale="ar-iq"
                                                                    :allowed-hours="allowedHour"
                                                                    v-model="editeTimeOpen.to_time" required>
                                                                </v-time-picker>
                                                            </v-col>


                                                            <!--Edite-->
                                                            <v-col xs3 v-else style="text-align:right">
                                                                <h2 style="color:#000">الى الساعه : </h2>
                                                                <v-time-picker :rules="to_timeRules"
                                                                    :min="editeTimeOpen.from_time" disabled="hidden"
                                                                    locale="ar-iq" :allowed-hours="allowedHour"
                                                                    v-model="editeTimeOpen.to_time" required>
                                                                </v-time-picker>
                                                            </v-col>










                                                            <v-col>
                                                                <v-select background-color="#fff" height="50"
                                                                    item-value="id" item-text="status_name_ar"
                                                                    :items="day_status" placeholder="الحاله"
                                                                    target="#dropdown-example" return-object
                                                                    v-model="editeTimeOpen.status" required
                                                                    :rules="TimeStatusRules"></v-select>

                                                                <div v-if="!is_editeTimeOpen">


                                                                    <v-select background-color="#fff" height="50"
                                                                        item-value="id"
                                                                        item-text="reservation_type_name"
                                                                        :items="reservation_type"
                                                                        placeholder="نوع الحجز"
                                                                        target="#dropdown-example" return-object
                                                                        @change="change_reservation_type()"
                                                                        v-model="editeTimeOpen.reservation_type"
                                                                        required :rules="ReservationTypeRules">
                                                                    </v-select>

                                                                    <v-text-field background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==2"
                                                                        placeholder=" اختار العدد " type="number"
                                                                        v-model="editeTimeOpen.reservations_number">
                                                                    </v-text-field>



                                                                    <v-text-field background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==1"
                                                                        placeholder=" اختار عدد دقائق الحجز "
                                                                        type="number"
                                                                        v-model="editeTimeOpen.reservation_duration">
                                                                    </v-text-field>
                                                                </div>







                                                                <!--Edite-->
                                                                <div v-else>


                                                                    <v-select disabled="hidden" background-color="#fff"
                                                                        height="50" item-value="id"
                                                                        item-text="reservation_type_name"
                                                                        :items="reservation_type"
                                                                        placeholder="نوع الحجز"
                                                                        target="#dropdown-example" return-object
                                                                        @change="change_reservation_type()"
                                                                        v-model="editeTimeOpen.reservation_type"
                                                                        required :rules="ReservationTypeRules">
                                                                    </v-select>

                                                                    <v-text-field disabled="hidden"
                                                                        background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==2"
                                                                        placeholder=" اختار العدد " type="number"
                                                                        v-model="editeTimeOpen.reservations_number">
                                                                    </v-text-field>



                                                                    <v-text-field disabled="hidden"
                                                                        background-color="#fff"
                                                                        v-if="editeTimeOpen.reservation_type.id==1"
                                                                        placeholder=" اختار عدد دقائق الحجز "
                                                                        type="number"
                                                                        v-model="editeTimeOpen.reservation_duration">
                                                                    </v-text-field>
                                                                </div>






                                                            </v-col>
                                                        </v-layout>

                                                        <v-row>

                                                            <v-flex xs1 md9 sm9>

                                                            </v-flex>

                                                            <v-flex xs11 md3 sm3>
                                                                <v-layout row wrap>
                                                                    <v-flex xs4 pr-3>
                                                                        <v-btn color="primary" @click="save_time()"
                                                                            style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;width:100%">

                                                                            حفظ
                                                                        </v-btn>
                                                                    </v-flex>
                                                                    <v-flex xs4 pr-3>
                                                                        <v-btn color="red"
                                                                            @click="time_dialog=false;close_time()"
                                                                            style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;color:#fff;width:100%">

                                                                            الغاء
                                                                        </v-btn>

                                                                    </v-flex>
                                                                </v-layout>
                                                                <br>

                                                            </v-flex>



                                                        </v-row>
                                                    </div>
                                                    <v-layout style="background-color:#fff">
                                                        <v-flex xs9>

                                                        </v-flex>
                                                        <v-flex xs3 pl-4>
                                                            <v-btn color="red" @click="DayDialog=false"
                                                                style="text-align:left;float:left;color:#fff;">

                                                                خروج
                                                            </v-btn>

                                                        </v-flex>
                                                    </v-layout>
                                                </v-card>

                                            </v-dialog>







                                        </v-flex>

                                        <v-flex xs4>

                                        </v-flex>
                                    </v-row>





                                </v-form>




                                <!-- <v-btn color="primary" @click="step4()">
                                        التالي
                                    </v-btn> -->

                                <v-btn @click="submit" color="success" style="margin-left:10px">
                                    <span v-show="!loading">حفظ</span>
                                    <v-progress-circular indeterminate color="white" v-show="loading">
                                    </v-progress-circular>
                                </v-btn>

                                <v-btn text v-if="editedItem.address_id==null" @click="step2()"> السابق</v-btn>
                                <v-btn text v-else @click="step1()"> السابق</v-btn>


                            </v-card>


                        </v-stepper-content>
                        <!--End Date Info-->


                        <!--Date Price-->
                        <v-stepper-content step="4">
                            <v-card class="add_card mb-12" color="#cac0c057" height="443px" flat>

                                <v-form ref="valid_payment" v-model="valid_payment">

                                    <v-layout row wrap pr-4>


                                        <v-flex xs3 md1 sm1 pt-4>نوع العمله</v-flex>
                                        <v-flex xs9 md9 sm9>

                                            <v-radio-group :rules="is_dollarsRul" required
                                                v-model="editedItem.price.is_dollars">
                                                <v-layout row wrap>

                                                    <v-flex xs4 md1 sm1>
                                                        <v-radio label="دينار" :value="1"></v-radio>
                                                    </v-flex>
                                                    <v-flex xs4 md1 sm1>
                                                        <v-radio @change="DollarsDialog=true" label="دولار" :value="2">
                                                        </v-radio>
                                                    </v-flex>




                                                </v-layout>



                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>

                                    <!-- <v-layout row wrap v-if="editedItem.price.is_dollars==2" pr-4>
                                            <v-flex xs3 md1 sm1 pt-5 pr-1>
                                                <span style="font-size:13px;padding-top:6px">١ دولار امريكي = </span>
                                            </v-flex>



                                            <v-flex xs4 md2 sm2>

                                                <v-text-field placeholder=" سعر صرف الدولار" data-vv-name="name"
                                                    height="50" background-color="#fff"
                                                    v-model="editedItem.owner.CurrencyTransfers_dollar_price"
                                                    :rules="dollar_priceRul">
                                                </v-text-field>


                                            </v-flex>

                                            <v-flex xs2 md1 sm1 pt-5><span style="font-size:15px;padding-top:6px">دينار
                                                    عراقي</span>
                                            </v-flex>



                                        </v-layout> -->

                                    <v-layout row wrap pl-2>
                                        <v-flex xs10 md11 sm11 pr-5 md3 sm3>

                                            <v-text-field background-color="#fff" height="50" placeholder=" سعر الحجز"
                                                data-vv-name="name" v-model="editedItem.price.price_value"
                                                :rules="numberRul" required>
                                            </v-text-field>
                                        </v-flex>

                                        <v-flex xs2 md1 sm1 pr-1 pt-5>
                                            <span style="font-weight:bold" v-if="editedItem.price.is_dollars==1">
                                                دينار
                                            </span>

                                            <span style="font-weight:bold" v-else-if="editedItem.price.is_dollars==2">
                                                دولار
                                            </span>

                                        </v-flex>
                                    </v-layout>




                                    <v-layout row wrap pl-2 pr-4>

                                        <span>طريقه الدفع :</span>
                                    </v-layout>

                                    <v-layout row wrap pl-2 pr-4>
                                        <v-radio-group @change="tr()" :rules="paymentRul" required
                                            v-model="editedItem.price.payment_when_receiving">
                                            <v-radio label="الحجز بدون بطاقة دفع" :value="3"></v-radio>
                                            <v-radio label=" الدفع عن طريق بطاقه دفع" :value="1"></v-radio>
                                            <v-radio label=" الدفع عن طريق بطاقه او بدون بطاقه" :value="2">
                                            </v-radio>
                                        </v-radio-group>

                                    </v-layout>

                                    <v-layout pl-2 pr-4 row wrap
                                        v-if="editedItem.price.payment_when_receiving==1 || editedItem.price.payment_when_receiving==2 ">
                                        <v-checkbox v-model="editedItem.price.is_tasdid_payment"
                                            label="الدفع عن طريق فاتوره تسديد">
                                        </v-checkbox>
                                        <v-checkbox v-model="editedItem.price.is_zaincash_payment"
                                            label="الدفع عن طريق زين كاش"></v-checkbox>
                                    </v-layout>



                                    <v-layout v-if="by_card" row wrap pl-2 pr-4>
                                        <v-flex xs12 md4 sm4 pa-1>
                                            <v-checkbox v-model="editedItem.price.free_cancellation"
                                                label="امكانية الغاء الحجز" required></v-checkbox>
                                        </v-flex>



                                        <v-flex xs12 md3 sm3 pa-1 v-if="editedItem.price.free_cancellation" pl-4>
                                            <v-text-field prepend-inner-icon="fas fa-percent" background-color="#fff"
                                                class="deucation_txfield" height="40" placeholder="  نسبة الاستقطاع"
                                                data-vv-name="name" :disabled="!editedItem.price.free_cancellation"
                                                :readonly="!editedItem.price.free_cancellation"
                                                v-model="editedItem.price.cancellation_deduction_ratio"
                                                :rules="numberRul1">
                                            </v-text-field>
                                        </v-flex>


                                    </v-layout>


                                    <!-- <v-layout row wrap pl-2 pr-4>
                                        <v-flex xs4 pa-1>
                                            <v-checkbox v-model="editedItem.possib_reserving_period"
                                                label="امكانية حجز فتره" required></v-checkbox>
                                        </v-flex>







                                    </v-layout> -->




                                </v-form>
                            </v-card>

                            <v-btn @click="submit" color="success" style="margin-left:10px">
                                <span v-show="!loading">حفظ</span>
                                <v-progress-circular indeterminate color="white" v-show="loading">
                                </v-progress-circular>
                            </v-btn>

                            <v-btn @click="step3()" text>السابق</v-btn>
                        </v-stepper-content>
                        <!--Date Price-->


                        <!--ItemFeatures-->
                        <!-- <v-stepper-content step="5">
                                <v-card class="add_card mb-12" color="#cac0c057" height="500px" flat>






                                    
                                    <v-row style="padding-right: 15px;padding-left: 15px;">

                                        <v-flex xs12 pa-3 pr-2 pl-2>

                                            <v-btn @click="AddItemFeatures" color="success"
                                                style="height: 28px;margin-bottom: 9px;">
                                                <v-icon>
                                                    fas fa-plus
                                                </v-icon>
                                                اضافة ميزات اضافيه

                                            </v-btn>

                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-right">#</th>
                                                            <th class="text-right">اسم الميزه</th>
                                                            <th class="text-right">سعر الميزه</th>
                                                            <th class="text-right"></th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        <tr v-for="item in editedItem.ItemFeatures" :key="item">
                                                            <td> {{ item.id}} </td>
                                                            <td>{{item.feature_name}}</td>
                                                            <td>{{item.feature_price}}</td>

                                                            <td>



                                                                <v-icon small class="mr-2"
                                                                    @click="editItemFeatures(item)">
                                                                    mdi-pencil
                                                                </v-icon>
                                                                <v-icon small @click="DeleteItemFeatures(item)">
                                                                    mdi-delete
                                                                </v-icon>

                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            <v-dialog v-model="ItemFeaturesDialog" width="950" height="500">
                                                <v-form ref="valid_ItemFeatures" v-model="valid_ItemFeatures">
                                                    <v-card>
                                                        <v-continer>



                                                            <v-layout row wrap style="padding:50px">

                                                                <v-flex xs12>
                                                                    <v-text-field background-color="#fff" height="50"
                                                                        placeholder="اسم الميزه" data-vv-name="name"
                                                                        v-model="editeItemFeatures.feature_name"
                                                                        required>
                                                                    </v-text-field>

                                                                </v-flex>

                                                                <v-flex xs12>
                                                                    <v-text-field background-color="#fff" height="50"
                                                                        placeholder="سعر الميزه" data-vv-name="name"
                                                                        v-model="editeItemFeatures.feature_price"
                                                                        :rules="numberRul" required>
                                                                    </v-text-field>
                                                                </v-flex>

                                                                <v-btn color="primary" @click="save_ItemFeatures()"
                                                                    style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;">

                                                                    حفظ
                                                                </v-btn>

                                                                <br>


                                                            </v-layout>





                                                        </v-continer>

                                                    </v-card>



                                                </v-form>
                                            </v-dialog>







                                        </v-flex>

                                        <v-flex xs4>

                                        </v-flex>
                                    </v-row>












                                </v-card>
                                <v-btn color="primary" @click="step6()">
                                    التالي
                                </v-btn>

                                <v-btn text @click="step4()"> السابق</v-btn>


                            </v-stepper-content> -->
                        <!--ItemFeatures-->

                        <!-- 
                            <v-stepper-content step="6">

                                <v-card class="add_card mb-12" flat color="#cac0c057" height="443px">


                                    <v-layout row wrap>


                                        <v-flex pa-5>
                                            <input type="file" style="display: none" ref="image" accept="image/*"
                                                @change="onFilePicked">
                                        </v-flex>
                                    </v-layout>



                                    <span>{{img_name}}</span>

                                    <v-layout row wrap>
                                        <v-row justify="center">
                                            <v-flex xs6 md2 sm2 v-for="(img ,index) in images" pa-2 :key="img">

                                                <v-card height="150" width="150" class="card_img">
                                                    <v-img :src="img" height="150" width="150" class="card_img"
                                                        v-if="img!='a'">
                                                        <v-btn icon=""
                                                            style="padding-right: 24px;position:relative;left: 46px;"
                                                            @click="delete_img(editedItem.images[index].id,index)">
                                                            <v-icon color="#fff">fas fa-window-close</v-icon>
                                                        </v-btn>

                                                    </v-img>

                                                    <v-btn icon v-else @click='pickFile(index)'>
                                                        <v-icon style="margin:0px" size="40">fas fa-plus-square</v-icon>
                                                    </v-btn>


                                                </v-card>
                                            </v-flex>
                                        </v-row>


                                    </v-layout>


                                </v-card>

                                <v-btn @click="submit" color="success" style="margin-left:10px">
                                    <span v-show="!loading">حفظ</span>
                                    <v-progress-circular indeterminate color="white" v-show="loading">
                                    </v-progress-circular>
                                </v-btn>


                                <v-btn @click="e1 = 4" text>السابق</v-btn>


                            </v-stepper-content> -->




                    </v-stepper-items>
                </v-stepper>
                </v-container>










            </v-card>

        </v-form>
        <v-dialog v-model="DollarsDialog" max-width="600" max-height="400" persistent>

            <v-card color="#fff" height="auto" width="auto">
                <v-form ref="valid_DollarPrice" v-model="valid_DollarPrice">

                    <v-layout row wrap pb-5 pr-4 pl-2>
                        <v-flex xs3 md2 sm2 pt-5 style="font-size: 14px;
    margin-top: 26px;
    margin-right: 0px;
    padding-right: 6px;"><span>١ دولار امريكي = </span></v-flex>
                        <v-flex xs7 md8 sm8 pt-5 pb-5 pr-4 pl-4>
                            <v-lable>سعر صرف الدولار</v-lable>
                            <v-text-field filled v-model="editedItem.owner.CurrencyTransfers_dollar_price" height="50"
                                style="" placeholder=" سعر صرف الدولار  " required :rules="dollar_priceRul">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs2 md2 sm2 style="    margin-top: 61px;"><span
                                style="font-size:15px;padding-top:6px">دينار
                                عراقي</span></v-flex>
                    </v-layout>

                    <v-layout row wrap pt-5 pb-5 pr-4 pl-2>

                        <v-flex xs3 pr-4>
                            <v-btn style="width:100%" @click="ChangeDollars()" color="success">تغير</v-btn>
                        </v-flex>

                        <v-flex xs3 pr-2 pt-1>
                            <v-btn style="width:100%" @click="closeChangeDollars()" color="error">خروج</v-btn>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card>

        </v-dialog>

    </div>


</template>

<style>
    .without_ampm::-webkit-datetime-edit-ampm-field {
        display: none;
    }

    input[type=time]::-webkit-clear-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        appearance: none;
        margin: -10px;
    }
</style>

<script>
    // import moment from 'moment';
    import moment from 'moment/src/moment'
    const axios = require('axios');

    import GoogleMap from '../components/map'
    // import {
    //     EventBus
    // } from './event-bus.js';
    import {
        EventBus
    } from '../event-bus.js';



    export default {

        props: {
            'item_ID': Number,
            'hidden': Boolean
        },

        data: () => ({
            dialog: false,
            ss: '',
            DollarsDialog: false,
            is_editeTimeOpen: false,
            steepers: [

                {
                    title: 'المعلومات الاساسيه',
                    icon: 'fas fa-edit fa-2x',
                    to: '/mainInfo'
                },


                {
                    title: 'معلومات الموقع',
                    icon: 'fas fa-map-marker-alt fa-2x',
                    to: '/AddressInfo'
                },

                {
                    title: 'معلومات اوقات الحجز',
                    icon: 'fas fa-clock fa-2x',
                    to: '/Bookingtimes'
                },

                // {
                //     title:'معلومات سعر الحجز',
                //     icon:'fas fa-dollar-sign fa-2x'
                // },

                //  {
                //     title:'ميزات اضافيه',
                //     icon:'fas fa-archive fa-2x'
                // },

                // {
                //     title:'الصور',
                //     icon:'fas fa-images fa-2x'
                // },
            ],
            owner: [],
            steeps: false,
            allwayhidden: false,
            valid_Primery: false,
            // img_url: 'http://127.0.0.1:9999',
            item_id: '',
            start: null,
            time_dialog: false,
           // xc: [],
            img_name: '',
            end: null,
            state: '',
            valid_day_open: false,


            errors_address: [],
            errors_payment: [],
            er_date: true,
            lastcategory: [],
            lastscategory: false,
            radioPayment: ["0", "1"],
            errors_date: [],
            day_status: [{
                id: 23,
                status_name_ar: 'مفتوح'
            }, {
                id: 22,
                status_name_ar: 'مغلق'
            }],
            DayDialog: false,
            msg_error: [],
            ItemFeaturesDialog: false,
            er_address: true,
            valid_ItemFeatures: false,
            valid_payment: false,

            ItemFeatures: [{
                id: '',
                item_id: '',
                feature_name: '',
                feature_price: ''

            }],



            editeItemFeatures: {
                id: '',
                item_id: '',
                feature_name: '',
                feature_price: ''
            },



            er_payment: true,

            editeTimeOpen: {

                id: '',
                action_id: '',
                from_time: '',
                reservation_duration: '',

                to_time: '',
                max_request: '',
                SubTime: [

                ],
                reservations_number: '',
                status: {
                    id: '',
                    status_name_ar: ''
                },
                reservation_type: {
                    id: '',
                    reservation_type_name: ''
                },

            },

            editeDayOpen: [{
                    id: '',
                    every_day: 0,
                    reservation_type: {
                        id: '',
                        reservation_type_name: ''
                    },
                    work_day: '',
                    status: {
                        id: '',
                        status_name_ar: ''
                    },
                    reservations_number: '',

                    time_to_open: [{
                            id: '',
                            action_id: '',
                            from_time: '',
                            to_time: '',
                            SubTime: [{
                                id: '',
                                time_to_open_id: '',
                                from_sub_time: '',
                                to_sub_time: '',
                                active: '',
                            }],
                            max_request: '',
                            reservations_number: '',
                            reservation_duration: '',
                            status: {
                                id: '',
                                status_name_ar: ''
                            },
                            reservation_type: {
                                id: '',
                                reservation_type_name: ''
                            },
                        },


                    ],
                }]



                ,

            er1: true,
            reservation_type: [{
                    id: 1,
                    reservation_type_name: 'اختيار فتره حجز '
                },
                {
                    id: 2,
                    reservation_type_name: 'اختيار عدد'
                },
                {
                    id: 3,
                    reservation_type_name: 'عدد غير محدود'
                }
            ],
            //    ['غير محدد','اختيار عدد'],
            e1: 1,

            switch2: false,
            radios: 'radio-1',
            submit_name: '',
            errors_primery: [],
            address_error: '',
            item_new_price: '',
            always_hidden: false,
            menu_date_from: false,
            menu_date_to: false,
            editdailog: false,
            user_info: {},
            token: '',
            err_show: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            map_dialog: false,
            modal2: false,
            book_dur_status: false,
            book_num_status: false,
            offer_info: {


            }

            ,
            reve_type: [{
                id: 0,
                duration: 'غير محدود'
            }, {
                id: 1,
                duration: 'اختيار فترة'
            }, {
                id: 2,
                duration: 'اختيار عدد لليوم '
            }],
            reve_type1: [{
                id: 0,
                duration: 'غير محدود'
            }, {
                id: 1,
                duration: 'اختيار فترة'
            }, {
                id: 2,
                duration: 'اختيار عدد  '
            }],
            error: '',
            msg_error_txt: 'هذا اليوم مكرر ',


            page: 1,
            pageCount: 0,
            items: [],
            xc:[],
            reservation_num: false,
            editedIndex: -1,
            editedIndexday: -1,
            countries: [{
                name: 'العراق',
                id: 1
            }],
            editedIndextime: -1,

            editedIndexItemFeatures: -1,

            editedItem: {
                item_name: '',
                address_id:[],
                last_category_id: '',
                owner: {
                    CurrencyTransfers: {
                        dollar_price: ''
                    },
                },
                status: {
                    id: ''
                },
                id: '',
                item_description: '',
                possib_reserving_period: '',
                sub_category: {
                    category_id: 0,
                    id: 0,
                    name: "",

                },
                Address: {
                    address_descraption: '',
                    province: {
                        id: '',
                        country_id: 1,

                    },


                    long: '',
                    lat: ''

                },

                ItemFeatures: [


                ],
                images: [{
                        "image_url": ''
                    },
                    {
                        "image_url": ''
                    },
                    {
                        "image_url": ''
                    },
                    {
                        "image_url": ''
                    }
                ],

                price: {
                    id: '',
                    price_value: '',
                    payment: '',
                    is_tasdid_payment: '',
                    is_zaincash_payment: '',
                    is_dollars: '',
                    deduction: 0,
                    payment_when_receiving: '',
                    free_cancellation: '',
                    payment_after_awhile: '',
                    installments: '',
                    cancellation_deduction_ratio: '0',

                },


                calories: 0,
                fat: 0,
                carbs: 0,
                disc: '',
                protein: 0,
                day_to_open: [

                    {
                        id: '',
                        work_day: '0',
                        every_day: 0,
                        status: {
                            id: '',
                            status_name_ar: ''
                        },

                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        time_to_open: [
                            //    {
                            //          SubTime:[
                            //                 {
                            //                     id:'',
                            //                     time_to_open_id :'',
                            //                     from_sub_time:'',
                            //                     to_sub_time:'',
                            //                     active:'',   
                            //                 }
                            //             ]
                            //    }

                        ],


                    },
                    {
                        id: '',
                        work_day: '1',
                        get_response: '',
                        every_day: 0,
                        status: {
                            id: '',
                            status_name: ''
                        },
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        time_to_open: [],

                    },
                    {
                        id: '',
                        work_day: '2',
                        every_day: 0,
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        reservations_number: '',
                        time_to_open: [],


                    },
                    {
                        id: '',
                        every_day: 0,
                        work_day: '3',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        time_to_open: [],


                    },
                    {
                        id: '',
                        work_day: '4',
                        every_day: 0,
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        time_to_open: [],


                    },
                    {
                        id: '',
                        work_day: '5',
                        every_day: 0,
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        reservations_number: '',
                        time_to_open: [],


                    },
                    {
                        id: '',
                        every_day: 0,
                        work_day: '6',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        time_to_open: [],


                    },
                ],

            },
            valid: false,

            deduction: '',
            days: ["السبت", "الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة", "كل الايام"],
            times: ["1:00 م", "2:00 م", "3:00 م", "4:00 م ", "5:00 م", "6:00 م", "7:00 م ", "8:00 م", "9:00 م",
                "10:00 م",
                "11:00 م", "12:00 م",
                "1:00 ص", "2:00 ص", "3:00 ص", "4:00 ص ", "5:00 ص", "6:00 ص", "7:00 ص ", "8:00 ص", "9:00 ص",
                "10:00 ص",
                "11:00 ص", "12:00 ص"
            ],

            img_cliced: -1,
            is_empty_editeTimeOpen: false,
            day_to_open: [{

                every_day: 0,
                every_time: 0,
                from_day: "",
                from_time: "",
                to_day: "",
                to_time: "",
                max_requests: 0
            }],

            AddressRules: [
                v => v.length > 0 || 'هذا الحقل مطلوب  ',

                //  this.editedItem.address_id.length > 0 || "هذا الحقل مطلوب "

            ],

            nameRules: [
                v => !!v || 'هذا الحقل مطلوب  ',

            ],
            StatusRules: [
                v => !!v || 'هذا الحقل مطلوب  ',

            ],


            CurrencyTransfers_method: '',
            numberRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],

 provRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

            ],


            addDescRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

            ],

            // numberRul
            dollar_priceRul: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v) || 'يجب ادخال رقم ',
                (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'يجب ادخال رقم ',
            ],


            ReservationTypeRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v.id) || 'يجب ادخال رقم ',

            ],

            to_timeRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],

            TimeStatusRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v.id) || 'يجب ادخال رقم ',

            ],
            map: false,
            paymentRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],

            is_dollarsRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],



            DayStatusRules: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],


            numberRul1: [

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            descriptRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                /// v => v.length >= 20 || 'يجب ان يكون الوصف اكثر من ٢٠ حرف ',
            ],
            loading: false,
            reservation_time: false,
            province: [],
            imageName: '',
            imageUrl: '',
            by_card: '',
            imageFile: '',
            payment: false,
            images: ['a', 'a', 'a', 'a'],
            free_cancellation: false,
            deductionvalue: 0,
            everyday: false,

            is_everyy_day: false,
            everytime: false,
            fromday: '',
            today: '',
            fromtime: '',
            totime: '',
            current_page: 1,
            SubTime: [
                // {
                //     id:'',
                //     time_to_open_id :'',
                //     from_sub_time:'',
                //     to_sub_time:'',
                //     active:'',   
                // }
            ],
            last_page: 0,
            offer_dialog: false,
            subcategory: [],
            sub_category_id: '',
            ItemStatus: [],
            category: [],
            reserv_type_id1: -1,
            valid_day: true,
            dollars: false,

            allowedHour: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                24
            ],
            lat: 0,
            lng: 0,


        }),



        methods: {


            notEmptyObject(someObject) {
                return Object.entries(someObject).length
            },
            closeChangeDollars() {

                if (this.editedItem.owner.CurrencyTransfers_dollar_price == null || this.editedItem.owner
                    .CurrencyTransfers_dollar_price == '') {
                    this.editedItem.price.is_dollars = 1;
                } else {
                    this.editedItem.price.is_dollars = 2;
                }
                this.DollarsDialog = false

            },

            ChangeDollars() {

                if (this.$refs.valid_DollarPrice.validate()) {

                    if (this.editedItem.owner.CurrencyTransfers_dollar_price == null || this.editedItem.owner
                        .CurrencyTransfers_dollar_price == '') {
                        this.editedItem.price.is_dollars = 1;
                    }
                    const Swal = require('sweetalert2')


                    Swal.fire({
                        title: "هل انت متاكد من تغير سعر تصريف الادولار ؟",

                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'نعم',
                        cancelButtonText: 'خروج'

                    }).then((result) => {
                        if (result.value) {

                            var url_Currency = '';
                            if (this.CurrencyTransfers_method == 'update') {
                                url_Currency = "v2/currency_transfers/owner/update";
                            } else {
                                url_Currency = "v2/currency_transfers/owner/set";
                            }
                            var data = {
                                dollar_price: this.editedItem.owner.CurrencyTransfers_dollar_price

                            };

                            this.$http({
                                method: 'post',
                                url: url_Currency,
                                data: data,
                                headers: {

                                }

                            }).then(response => {
                                response
                                this.DollarsDialog = false;

                                this.$swal('تم', "تم تغير سعر صرف العمله ", 'success')

                                this.editedItem.price.is_dollars = 2;

                            }).catch(error => {
                                error


                            }).finally(d => {
                                d
                            });



                        }
                    })



                } else {
                    this.editedItem.price.is_dollars = 1;
                }

            },
            is_dollars() {
                if (this.editItem.price.is_dollars == 2) {

                    this.dollars = true;
                } else {
                    this.dollars = false;

                }

            },

            checkEmpty() {
                if (this.is_empty_editeTimeOpen === true) {
                    this.time_dialog = true
                }

            },
            // everyy_day() {



            //     for (var i = 0; i < 7; i++) {

            //         this.editedItem.day_to_open[i].every_day = this.editedItem.day_to_open[0].every_day;

            //     }




            // },

            GetItemsStatus() {
                // this.$http({
                //     method: 'get',
                //     url: 'owner/v2/StatusByStatusType/3',
                // }).then(response => {
                //     this.ItemStatus = response.data.data
                // }).catch(error => {
                //     error
                // }).finally(s => {
                //     s
                // })
            },
            GetOwnerInfo() {
                this.$http({
                    method: 'get',
                    url: 'owner/v2/OwnerInfo',
                    headers: {

                    }

                }).then(response => {

                    this.owner = response.data.data
                    if (this.owner.address.length == 0) {
                        this.editedItem.address_id = null;
                    }


                    this.editedItem.sub_category.category_id = this.owner.owner_category.category_id;

                    var url = "v2/info/subCategoryCat_id/" + this.owner.owner_category.category_id;
                    this.$http({
                        method: 'get',
                        url: url,
                    }).then(response => {
                        this.subcategory = response.data.data;



                    })


                }).catch(error => {
                    error

                }).finally(s => {
                    s

                })

            },

            getLatCategory() {




                if (this.subcategory[0].LastCategory.length !== 0) {

                    var url = "v2/info/SubCategories/" + this.editedItem.sub_category.id;
                    this.$http({
                        method: 'get',
                        url: url,
                    }).then(response => {
                        this.lastcategory = response.data.data[0].LastCategory;




                    })

                    this.lastscategory = true;
                    //this.lastcategory = this.subcategory[0].LastCategory;

                } else {

                    this.lastscategory = false;

                }



            },
            close_day() {
                this.DayDialog = false;
                this.time_dialog = false;
                this.allowedHour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24
                ];

            },
            formatAMPM(date) {
                if (typeof date === "string") {
                    let [hours, minutes] = date.split(":");
                    let ampm = "ص";

                    if (Number(hours) > 12) {
                        hours = Number(hours) - 12;
                        ampm = "م";
                    }

                    return `${hours}:${minutes} ${ampm}`;

                } else if (date instanceof Date) {
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    let ampm = hours >= 12 ? 'م' : "AM";

                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? "0" + minutes : minutes;

                    let strTime = hours + ":" + minutes + " " + ampm;

                    return strTime;
                }

                return date;
            },
            xx() {


                // if (this.editeDayOpen.time_to_open.length !== 0) {

                for (var j = 0; j < this.editeDayOpen.time_to_open.length; j++) {
                    var hours1 = this.editeDayOpen.time_to_open[j].from_time.split(":");
                    hours1 = parseInt(hours1[0]);
                    var hours2 = this.editeDayOpen.time_to_open[j].to_time.split(":");
                    hours2 = parseInt(hours2[0]);
                    var a = this.allowedHour.indexOf(hours1);
                    var b = this.allowedHour.indexOf(hours2);
                    var removedFruits = this.allowedHour.splice(a, b - a + 1);
                    removedFruits



                }



                // }


                this.editedIndextime = -1;
                this.editeTimeOpen = {

                    id: '',
                    action_id: '',
                    from_time: '',
                    reservation_duration: '',
                    to_time: '',
                    max_request: '',
                    SubTime: [

                    ],
                    reservations_number: '',
                    time_between: '',
                    status: {
                        id: '',
                        status_name_ar: ''
                    },
                    reservation_type: {
                        id: '',
                        reservation_type_name: ''
                    },

                }

            },
            cancel_day() {
                this.allowedHour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24
                ];
                if (this.$refs.valid_day_open.validate()) {

                    if (this.editeDayOpen.status.id == 23 && this.editedItem.day_to_open[this.editedIndexday]
                        .time_to_open
                        .length == 0) {



                        this.$swal('    خطاء', "يرجئ اختيار وقت اذا كان الحجز مفتوح في هاذا اليوم", 'error')


                    } else {
                        this.editedItem.day_to_open[this.editedIndexday].status['id'] = this.editeDayOpen.status.id;
                        this.DayDialog = false;
                        this.time_dialog = false;
                        this.editeTimeOpen = {

                            id: '',
                            action_id: '',
                            from_time: '',
                            to_time: '',
                            SubTime: [

                            ],
                            reservation_duration: '',
                            max_request: '',
                            reservations_number: '',
                            status: {
                                id: '',
                                status_name_ar: ''
                            },
                            reservation_type: {
                                id: '',
                                reservation_type_name: ''
                            },

                        }

                    }



                }





            },

            close_time() {

                this.allowedHour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24
                ];

            },
            close_time_Dailog() {
                this.editedIndextime = -1;
                this.time_dialog = false;
                this.editeTimeOpen = {}
            },


            editedTimeOpen(item) {
                this.is_editeTimeOpen = true;
                this.editedIndextime = this.editeDayOpen.time_to_open.indexOf(item)
                this.editeTimeOpen = Object.assign({}, item);


            },

            DeleteTimesOpen(item) {



                this.editedIndextime = this.editeDayOpen.time_to_open.indexOf(item)
                const Swal = require('sweetalert2')
                Swal.fire({
                    title: "هل انت متاكد من حذف الفتره",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم ',
                    cancelButtonText: 'خروج'

                }).then(() => {

                    for (var j = 0; j < this.editeDayOpen.time_to_open.length; j++) {
                        var hours1 = item.from_time.split(":");
                        hours1 = parseInt(hours1[0]);
                        var hours2 = item.to_time.split(":");
                        hours2 = parseInt(hours2[0]);
                        var a = this.allowedHour.indexOf(hours1) + 1;
                        var b = this.allowedHour.indexOf(hours2) + 1;

                        var removedFruits = this.allowedHour.push(a, b - a + 1);
                        removedFruits


                    }
                    this.time_dialog = false;
                    this.close_time();



                    if (item.id == 'undefined') {
                        // const index = this.editedItem.ItemFeatures.indexOf(item)
                        this.editeDayOpen.time_to_open.splice(this.editedIndextime, 1)

                    } else {
                        this.editeDayOpen.time_to_open.splice(this.editedIndextime, 1)


                        //        this.editeDayOpen.time_to_open.splice(this.editedIndextime, 1)
                        var url = "/v2/items/deleteTimeOpen/" + item.id;


                        axios({
                            method: 'delete',
                            url: url,
                            headers: {

                            }
                        }).then(response => {
                            response,


                            Swal.fire(
                                'تم الحذف بنجاح',
                                '',
                                'success'
                            )

                        }).catch(error => {
                            error
                        }).finally(d => {
                            d,

                            this.getitems();
                        });

                    }


                });








            },
            SetDayStatus() {

            },

            diff_minutes(dt2, dt1) {

                var diff = (dt2.getTime() - dt1.getTime()) / 1000;
                diff /= 60;
                diff
                //  return Math.abs(Math.round(diff));


            },

            diff_duration_fun(from, to) {
                var start = moment.duration(from, "HH:mm");
                var end = moment.duration(to, "HH:mm");
                return end.subtract(start);



            },
            save_time() {

                if (this.editeTimeOpen.reservation_duration == '' &&
                    this.editeTimeOpen.reservations_number == '' && this.editeTimeOpen.reservation_type.id !== 3) {
                    this.$swal('    خطاء', " يرجئ ملئ جميع الحقول", 'error')

                } else {




                    if (!this.editeTimeOpen.reservation_type.id == ' ' || !this.editeTimeOpen.reservation_type.id ==
                        null &&
                        !this.editeTimeOpen.to_time == ' ' || !this.editeTimeOpen.to_time == null
                    )

                    {
                        if (!this.editeTimeOpen.status.id == null || !this.editeTimeOpen.status.id == ' ' && !this
                            .editeTimeOpen.from_time == ' ' || !this.editeTimeOpen.from_time == null) {



                            if (this.editedIndextime > -1) {


                                Object.assign(this.editeDayOpen.time_to_open[this.editedIndextime], this.editeTimeOpen);
                                this.close_time_Dailog();




                            } else {






                                //check vaild peroid
                                var hours1 = this.editeTimeOpen.from_time.split(":");
                                var hours2 = this.editeTimeOpen.to_time.split(":");
                                for (var t = parseInt(hours1); t <= parseInt(hours2); t++) {

                                    for (var a = 0; a < this.allowedHour.length; a++) {
                                        if (!this.allowedHour.includes(t)) {
                                            return this.$swal('خطاء', "الفتره موجوده لايمكن اختيارها", 'error');

                                        }



                                    }


                                }

                                if (this.editeTimeOpen.reservation_type.id == 1) {

                                    var diffs = this.diff_duration_fun(this.editeTimeOpen.from_time, this.editeTimeOpen
                                        .to_time);
                                    var diff = diffs.hours() * 60 + diffs.minutes();
                                    var duration = this.editeTimeOpen.reservation_duration;
                                    if (diff < duration) {


                                        return this.$swal('خطاء', " فتره الحجز المختاره اكبر من الوقت المتاح", 'error');

                                    }
                                    var duration_num = diff / duration;
                                    duration_num = parseInt(duration_num);

                                    var x = 0;
                                    var durationx = [0];
                                    for (var j = 0; j < duration_num; j++) {

                                        x = durationx.push(duration);

                                    }


                                    var f2 = 0;


                                    for (var i = 0; i < duration_num; i++) {



                                        var f1;

                                        if (f2 !== 0) {



                                            f1 = this.editeTimeOpen.from_time;
                                            f1 = f2;
                                            x = parseInt(this.editeTimeOpen.reservation_duration) + parseInt(moment
                                                .duration(f1).asMinutes());

                                            f2 = moment.utc(moment.duration(x, "minutes").asMilliseconds()).format(
                                                "HH:mm");



                                        } else {

                                            var y = moment.duration(this.editeTimeOpen.from_time).asMinutes();
                                            f1 = moment.utc(moment.duration(y, "minutes").asMilliseconds()).format(
                                                "HH:mm");
                                            this.editeTimeOpen.from_time = f1;
                                            x = parseInt(this.editeTimeOpen.reservation_duration) + moment.duration(this
                                                .editeTimeOpen.from_time).asMinutes();
                                            f2 = moment.utc(moment.duration(x, "minutes").asMilliseconds()).format(
                                                "HH:mm");

                                        }








                                        this.editeTimeOpen.reservations_number = duration;
                                        this.editeTimeOpen.reservations_number = duration_num;


                                        this.editeTimeOpen.SubTime.push({
                                            id: '',
                                            time_to_open_id: '',
                                            from_sub_time: f1,
                                            to_sub_time: f2,
                                            reservation_type_id: 1,
                                            active: 1,
                                        });


                                    }

                                } else if (this.editeTimeOpen.reservation_type.id == 2) {






                                    for (i = 0; i < this.editeTimeOpen.reservations_number; i++) {






                                        //this.editeTimeOpen.reservations_number = duration;
                                        // this.editeTimeOpen.reservations_number = this.editeTimeOpen.reservations_number;


                                        this.editeTimeOpen.SubTime.push({
                                            id: '',
                                            time_to_open_id: '',
                                            from_sub_time: this.editeTimeOpen.from_time,
                                            to_sub_time: this.editeTimeOpen.to_time,
                                            time_to_open_reservation_type_id: 2,
                                            active: 1,
                                        });


                                    }


                                } else if (this.editeTimeOpen.reservation_type.id == 3) {
                                    this.editeTimeOpen.SubTime.push({
                                        id: '',
                                        time_to_open_id: '',
                                        from_sub_time: this.editeTimeOpen.from_time,
                                        to_sub_time: this.editeTimeOpen.to_time,
                                        time_to_open_reservation_type_id: 3,
                                        active: 1
                                    });

                                }





                                if (this.editeDayOpen.time_to_open.length !== 0) {

                                    // for (var k = 0; k < this.editeDayOpen.time_to_open.length; k++) {
                                    //     var hours1 = this.editeDayOpen.time_to_open.from_time.split(":");
                                    //     hours1 = parseInt(hours1[0]);
                                    //     var hours2 = this.editeDayOpen.time_to_open.to_time.split(":");
                                    //     hours2 = parseInt(hours2[0]);


                                    // }
                                    // var a = this.allowedHour.indexOf(hours1);
                                    // var b = this.allowedHour.indexOf(hours2);
                                    // var removedFruits = this.allowedHour.splice(a, b - a + 1);
                                    // removedFruits



                                }

                                this.editeDayOpen.time_to_open.push(
                                    this.editeTimeOpen

                                );

                            }

                            //  duration+=duration


                            Object.assign(this.editedItem.day_to_open[this.editedIndexday].time_to_open, this
                                .editeTimeOpen)





                            // for(var i=0;i<=this.editedItem.day_to_open[this.editedIndexday].time_to_open.length;i++)
                            // {
                            //     if(this.editedItem.day_to_open[this.editedIndexday]['status']==6)
                            //     {
                            //         this.editedItem.day_to_open[this.editedIndexday]['status']=6;
                            //         break;


                            //     }
                            //     else
                            //     {
                            //          this.editedItem.day_to_open[this.editedIndexday]['status']=7;

                            //     }


                            // }

                            this.close_time_Dailog();





                            // this.close()







                            // }






                        } else {
                            //const swal = require('sweetalert2')
                            this.$swal('    خطاء', " يرجئ ملئ جميع الحقول", 'error')

                        }
                    } else {
                        this.$swal('    خطاء', " يرجئ ملئ جميع الحقول", 'error')
                    }
                }



            },




            editItemFeatures(item) {
                this.editedIndexItemFeatures = this.editedItem.ItemFeatures.indexOf(item)
                this.editeItemFeatures = Object.assign({}, item)
                this.ItemFeaturesDialog = true;

            },
            AddItemFeatures() {
                this.editeItemFeatures = {};
                this.ItemFeaturesDialog = true;
            },

            DeleteItemFeatures(item) {



                const Swal = require('sweetalert2')






                Swal.fire({
                    title: "هل انت متاكد من الحذف ؟",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'

                }).then(() => {

                    if (item.id == 'undefined') {
                        const index = this.editedItem.ItemFeatures.indexOf(item)
                        this.editedItem.ItemFeatures.splice(index, 1)

                    } else {
                        const index = this.editedItem.ItemFeatures.indexOf(item)
                        this.editedItem.ItemFeatures.splice(index, 1)
                        var url = "/ItemFeatures/owner/delete/" + item.id;
                        axios({
                            method: 'delete',
                            url: url,
                            headers: {

                            }
                        }).then(response => {



                            response,
                            this.editedItem.images[index].image_url = 'a';
                            this.img_name = index;
                            this.images[index] = 'a';

                            Swal.fire(
                                'تم الحذف بنجاح',
                                '',
                                'success'
                            )

                        }).catch(error => {
                            error
                        }).finally(d => {
                            d,

                            this.getitems();
                        });

                    }

                })

                // confirm('Are you sure you want to delete this item?') &&this.editedItem.ItemFeatures.splice(index, 1)

            },
            save_ItemFeatures() {

                if (this.$refs.valid_ItemFeatures) {


                    if (this.editedIndexItemFeatures > -1) {

                        Object.assign(this.editedItem.ItemFeatures[this.editedIndexItemFeatures], this
                            .editeItemFeatures)

                    } else {
                        console.log(this.editeItemFeatures);

                        this.editedItem.ItemFeatures.push(this.editeItemFeatures)

                    }
                    this.ItemFeaturesDialog = false
                }


            },
            // save_date() {

            //     if (this.editeday_to_open.status.id == 6) {
            //         if (this.editeday_to_open.time_to_open[0].from_time == null || this.editeday_to_open.time_to_open[0]
            //             .from_time == '') {
            //             alert('يجب اختيار فتره اذا كانت حاله الحجز مفتوح في هاذا اليوم');
            //         } else if (this.$refs.valid_day_open.validate()) {
            //             Object.assign(this.day_to_open[this.editedIndexday], this.editeday_to_open)
            //             this.DayDialog = false

            //         }

            //     } else {
            //         if (this.$refs.valid_day_open.validate()) {
            //             Object.assign(this.day_to_open[this.editedIndexday], this.editeday_to_open)
            //             this.DayDialog = false
            //         }
            //     }

            // },

            save_date() {
                // alert(this.editedIndextime);

                if (this.editeDayOpen.status.id == 6) {
                    if (this.editeTimeOpen.from_time == null || this.editeTimeOpen.from_time == '') {
                        alert('يجب اختيار فتره اذا كانت حاله الخدمه مفتوح في هاذا اليوم');
                    } else if (this.$refs.valid_day_open.validate()) {
                        Object.assign(this.editedItem.day_to_open[this.editedIndextime], this.editedTimeOpen)
                        this.time_dialog = false

                    }

                } else {
                    if (this.$refs.valid_day_open.validate()) {
                        Object.assign(this.editedItem.day_to_open[this.editedIndextime], this.editedTimeOpen)
                        this.time_dialog = false
                    }
                }

            },

            editItem(item) {
                if (item.time_to_open.length === 0) {
                    this.is_empty_editeTimeOpen = true;
                } else {
                    this.is_empty_editeTimeOpen = false;

                }

                this.editedIndexday = this.editedItem.day_to_open.indexOf(item)
                /// alert(this.editedIndexday);
                //   alert(this.editedIndexday);
                this.editeDayOpen = Object.assign({}, item)
                this.DayDialog = true
                if (item.reservation_type.id == 2) {
                    this.reservation_num = true;
                } else {
                    this.reservation_num = false;

                }
            },



            getdays(index) {
                var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];

                return days[index];
            },

            getstatus(index) {
                if (index == 22) {
                    return 'مغلق';
                } else if (index == 23) {
                    return 'مفتوح';

                }


            },
            getstatuss(index) {
                if (index == 1) {
                    return 'غير محدد';
                } else if (index == 2) {
                    return 'اختيار عدد';

                }


            },
            getItemDetails() {

                this.user_info = this.$cookies.get('admin_info');

                var item_id;
                if (this.$route.params.id) {
                    item_id = parseInt(this.$route.params.id);

                } else {
                    item_id = this.$cookies.get('last_owner_item');
                }


                var url = "/v2/items/owner/OwnerItemsById/" + item_id;
                this.$http({
                        method: 'get',
                        url: url,
                        headers: {

                        }
                    })

                    .then(response => {
                        this.editedItem = response.data.data;

                         

                        this.map = true;
                        for (var i = 0; i < this.editedItem.Address.length; i++) {
                            this.xc.push(this.editedItem.Address[i].id)
                        }



                        if (this.editedItem.price.is_tasdid_payment == 1) {
                            this.editedItem.price.is_tasdid_payment = true
                        } else {
                            this.editedItem.price.is_tasdid_payment = false
                        }
                        // if (this.editedItem.price.is_zaincash_payment == 1) {
                        //     this.editedItem.price.is_zaincash_payment = true;
                        // } else {
                        //     this.editedItem.price.is_zaincash_payment = false
                        // }

                        if (this.editedItem.owner.CurrencyTransfers !== null) {
                            this.CurrencyTransfers_method = 'update';
                            this.editedItem.owner.CurrencyTransfers_dollar_price = this.editedItem.owner
                                .CurrencyTransfers.dollar_price;
                            ////  alert('sds');
                        } else {
                            this.CurrencyTransfers_method = 'add';
                            this.editedItem.owner.CurrencyTransfers_dollar_price = '';

                        }
                        if (this.editedItem.day_to_open[0].every_day)

                        {
                            this.is_everyy_day = true;
                        }

                        if (this.$route.params.id) {




                            if (this.editedItem.last_category_id !== null) {

                                this.editedItem.last_category_id = this.editedItem.last_category_id;
                                this.lastscategory = true;

                                this.lastcategory = this.editedItem.sub_category.LastCategory;
                            }

                            var url = "v2/info/subCategoryCat_id/" + this.editedItem.sub_category.category_id;
                            this.$http({
                                method: 'get',
                                url: url,
                            }).then(response => {
                                this.subcategory = response.data.data;



                            })

                        } else
                        
                        {

                            this.editedItem.item_name = '',
                                this.editedItem.sub_category.id = '',
                                this.editedItem.last_category_id = '',
                                this.editedItem.item_description = ''
                            this.editedItem.ItemFeatures = [],


                                this.editedItem.images = [{
                                        "image_url": ''
                                    },
                                    {
                                        "image_url": ''
                                    },
                                    {
                                        "image_url": ''
                                    },
                                    {
                                        "image_url": ''
                                    }
                                ]

                        }






                        //  this.DayOpen = response.data.data.day_to_open;
                        // if (this.editedItem.price.payment_when_receiving == 0) {
                        //     this.editedItem.price.payment_when_receiving = 2;
                        //     this.by_card = true;
                        // }

                        // if (this.editedItem.possib_reserving_period == 1) {
                        //     this.editedItem.possib_reserving_period = true;
                        //     this.by_card = false;
                        // } else {
                        //     this.editedItem.possib_reserving_period = false;
                        // }





                    })
            },


            delete_img(img_id, index) {


                this.user_info = this.$cookies.get('admin_info');


                const Swal = require('sweetalert2');



                Swal.fire({
                    title: "هل انت متاكد من الحذف ؟",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'

                }).then((result) => {
                    if (result.value) {
                        this.img_cliced = index;


                        this.editedItem.images[index].image_url = 'a';
                        this.img_name = index;
                        this.images[index] = 'a';

                        var url = "/v2/items/delete_image/" + img_id;
                        axios({
                            method: 'post',
                            url: url,
                            headers: {

                            }

                        }).then(response => {

                            response,
                            this.editedItem.images[index].image_url = 'a';
                            //not important
                            this.img_name = index;
                            this.images[index] = 'a';

                            Swal.fire(
                                'تم الحذف بنجاح',
                                '',
                                'success'
                            )

                        }).catch(error => {
                            error
                            //     this.$swal('خطاء', "خطاء بالاتصال", 'error')
                        }).finally(d => {
                            d,

                            this.getitems();
                        });



                    }
                })




            },


            cancel() {
                this.$refs.form.reset(),
                    this.$emit("changeStatus", false);
            },

            editt() {
                // alert(this.item_id);
                this.$emit("edite_item");

            },

            step1() {
                this.e1 = 1;
                this.Check_Primery();


            },

            step2() {

               // this.e1 = 2;
                this.Check_Primery();
                // if (this.editedItem.address_id == null) {
                //     this.e1 = 2;

                // } else {
                //      this.Check_Primery();
                //   //  this.e1 = 3;
                // }

            },

            step3() {
                this.e1 = 3;
                this.Check_address();
                this.Check_Primery();

                // this.Check_payment();


            },
            step4() {
                if (this.editedItem.status.id == 11 && this.editedItem.day_to_open[0].every_day == 0) {
                    var day;
                    day = this.editedItem.day_to_open;
                    if (day.some(day => day.status.id === 23)) {
                        day


                    } else {
                        day
                        return this.$swal('  تحذير', "  لايوجد يوم مفتوح لايمكن اختيار حاله المركز مفتوح", 'warning');
                    }
                    day
                }
                this.e1 = 4;
                this.Check_Primery();
                //this.Check_address();
             //   this.Check_date();
                // this.Check_payment();


            },
            step5() {
                if (this.editedItem.price.is_dollars == 2) {

                    if (this.editedItem.owner.CurrencyTransfers_dollar_price == null || this.editedItem.owner
                        .CurrencyTransfers_dollar_price == '') {
                        return this.$swal('  تحذير', "  يرجئ ادخال قيمه صرف الدولار اذا كانت العمله دولار ", 'warning');
                    } else {
                        this.e1 = 6;
                    }
                } else {
                    this.e1 = 6;
                }
                //  this.e1 = 5;
                this.Check_Primery();
                // this.Check_address();
              //  this.Check_payment();
               // this.Check_date();


            },

            step6() {
                this.e1 = 6;
                this.Check_Primery();
                //this.Check_address();
               // this.Check_payment();
          //      this.Check_date();


            },






            Check_date() {



                if (!this.$refs.form_day.validate()) {
                    this.er_date = false,
                        this.errors_payment.push("يرجئ ملئ الحقول");
                } else {
                    this.er_date = true;
                }

            },


            rules_check() {

                // if (this.editedItem.address_id !== null) {

                //     if (this.editedItem.address_id.length == 0 && this.owner.address.length > 0) {
                //         this.address_error = 'يرجئ اختيار موقع';

                //         this.errors_primery.push("يرجئ ملئ الحقول");
                //     } else {
                //         this.address_error = '';

                //     }

                // }
            },
            Check_Primery() {


                // alert(this.owner.address.length);
                this.errors_primery = [];


                if (!this.$refs.valid_Primery.validate()) {

                    this.errors_primery.push("يرجئ ملئ الحقول");
                }


                  if (this.xc.length == 0 && this.owner.address.length > 0) {
                        this.address_error = 'يرجئ اختيار موقع';
                        

                        this.errors_primery.push("يرجئ ملئ الحقول");
                    } else {
                        this.address_error = '';

                    }


                if (this.editedItem.address_id !== null) {

                    // if (this.editedItem.address_id.length == 0 && this.owner.address.length > 0) {
                    //     this.address_error = 'يرجئ اختيار موقع';
                        

                    //     this.errors_primery.push("يرجئ ملئ الحقول");
                    // } else {
                    //     this.address_error = '';

                    // }

                }



                // if (!this.$refs.valid_address.validate()) {

                //     this.errors_primery.push("يرجئ ملئ الحقول");
                // }

             

                // if (this.errors_address.length) {
                //     this.errors_primery = false;
                // } else {
                //     this.submit();
                //   //  this.er_address = true;
                // }




                ///   this.errors_payment = [];


                // if (!this.$refs.valid_payment.validate()) {
                //     this.errors_payment.push("يرجئ ملئ الحقول");
                // }

                if (this.editedItem.price.payment_when_receiving == 1 || this.editedItem.price.payment_when_receiving ==
                    2) {

                    if (this.editedItem.price.is_zaincash_payment == false && this.editedItem.price.is_tasdid_payment ==
                        false) {
                        this.er_payment = false;

                        this.errors_primery.push("يرجئ تحديد طريقه الدفع");
                    }

                }

                if (!this.editedItem.price.price_value) {

                    if (this.editedItem.price.payment_when_receiving !== 1 || this.editedItem.price
                        .payment_when_receiving !== 0 | this.editedItem.price
                        .payment_when_receiving !== 2) {


                        this.errors_primery.push("يرجئ تحديد طريقه الدفع");
                    }

                    this.errors_primery.push("يرجئ ملئ الحقول");
                }

                // if (this.errors_primery.length) {
                //     this.er_payment = false;
                // } else {
                //     this.er_payment = true;
                // }


                //owner.address


                if (this.errors_primery.length > 0) {
                    this.er1 = false;


                } else {
                //    alert('sd');
                  console.log('go');  
                    this.submit();
                  // this.Check_Primery();

                // if (this.editedItem.address_id == null) {
                   
                //     this.e1 = 2;

                // } else {
                //  this.submit();
                //     this.er1 = true;
                // }


                   
                    // this.submit();
                    // this.er1 = true;
                }


            },



            Check_address() {

                this.errors_address = [];

                if (!this.$refs.valid_address.validate()) {

                    this.errors_address.push("يرجئ ملئ الحقول");
                }

                if (!this.editedItem.Address.province.id || !this
                    .editedItem.Address.address_descraption) {
                    this.errors_address.push("يرجئ ملئ الحقول");
                }
                if (this.errors_address.length) {
                    this.er_address = false;
                } else {
                    this.submit();
                  //  this.er_address = true;
                }
            },


            Check_payment() {

                this.errors_payment = [];


                if (!this.$refs.valid_payment.validate()) {
                    this.errors_payment.push("يرجئ ملئ الحقول");
                }

                if (this.editedItem.price.payment_when_receiving == 1 || this.editedItem.price.payment_when_receiving ==
                    2) {

                    if (this.editedItem.price.is_zaincash_payment == false && this.editedItem.price.is_tasdid_payment ==
                        false) {
                        this.er_payment = false;

                        this.errors_payment.push("يرجئ تحديد طريقه الدفع");
                    }

                }

                if (!this.editedItem.price.price_value) {

                    if (this.editedItem.price.payment_when_receiving !== 1 || this.editedItem.price
                        .payment_when_receiving !== 0 | this.editedItem.price
                        .payment_when_receiving !== 2) {


                        this.errors_payment.push("يرجئ تحديد طريقه الدفع");
                    }

                    this.errors_payment.push("يرجئ ملئ الحقول");
                }

                if (this.errors_payment.length) {
                    this.er_payment = false;
                } else {
                    this.er_payment = true;
                }
            },





            getlocation(lat, lng) {

                this.editedItem.Address.lat = lat;
                this.editedItem.Address.long = lng
                // this.map_dialog = false;


            },
            price_percentage(v) {

                this.offer_info.new_price = this.offer_info.old_price;
                this.offer_info.new_price = this.offer_info.old_price - (this.offer_info.old_price * (v / 100));
                this.offer_info.discount_percentage = v;



            },







            change_timeday() {
                this.day_to_open.length = 0;
            },
            select_reser_type1(id) {

                this.reserv_type_id1 = id;
            },
            select_reser_type(id) {

                this.reserv_type_id = id;
                if (id == 1) {
                    this.book_dur_status = true;
                    this.book_num_status = false;
                } else if (id == 2) {
                    this.book_dur_status = false;
                    this.book_num_status = true;
                } else {
                    this.book_dur_status = false;
                    this.book_num_status = false;
                }
            },

            allowedHours(v) {
                v

                return false;
            },

            comput_maxRequest(id, time_duration, from_tim, to_tim) {

                if (id == 0) {
                    return 0;
                } else if (id == 1) {
                    // this.reservation_duration

                    var from_hour = parseInt(from_tim.split(":")[0])
                    var from_min = parseInt(from_tim.split(":")[1])


                    var to_hour = parseInt(to_tim.split(":")[0])
                    var to_min = parseInt(to_tim.split(":")[1])

                    var from = from_hour * 60 + from_min;


                    var to = to_hour * 60 + to_min;

                    var result = to - from;

                    var houer = parseInt(time_duration.split(":")[0]);
                    var minit = parseInt(time_duration.split(":")[1]);

                    var dur = houer * 60 + minit;

                    result = result / dur

                    return result

                } else {
                    return 0
                }




            },

            async get_single(imag, item_id) {
                // for (var i = 0; i < imag.length; i++) {
                //   //  alert(i);
                //   var imgg=
                //   {
                //        images:imag[i]
                //   };
                //   console.log(i);


                axios({
                        method: 'post',

                        url: 'v2/items/UploudeImage/' + item_id,
                        data: imag
                    })
                    .then(response => {
                        response

                    })


                //}
            },
            submit() {


// console.log(this.xc);
// this.xc.push(this.xc);

             //   this.Check_payment();


                // if (!this.er1) {
                //     this.step1();
                // } else if (!this.er_address && this.owner.address.length == 0) {
                //     return this.step2()
                // }
                
                
                // else if (!this.er_date) {
                //     this.step3();
                // } else if (!this.er_payment) {
                //     return this.step4();
                // }




                if (this.editedItem.price.free_cancellation) {
                    this.editedItem.price.free_cancellation = 1;
                } else {
                    this.editedItem.price.free_cancellation = 0;
                }


                if (this.editedItem.possib_reserving_period) {
                    this.editedItem.possib_reserving_period = 1;
                } else {
                    this.editedItem.possib_reserving_period = 0;
                }





                if (this.DayOpen == 0) {
                    this.$swal('تحذير', "    يجب اختيار جدول الحجز", 'warning')
                }



                this.user_info = this.$cookies.get('admin_info');


                if (this.$refs.form.validate()) {




                    if (this.editedItem.last_category_id == 'null') {
                        this.editedItem.last_category_id = '';


                    }
                    // if (this.editedItem.address_id == 'null' || this.editedItem.address_id == null) {
                    //     this.editedItem.address_id = [];

                    // }

    //  console.log(this.editedItem.address_id);

                    var data = {


                        item_name: this.editedItem.item_name,
                        item_description: this.editedItem.item_description,
                        sub_category_id: this.editedItem.sub_category.id,
                        last_category_id: this.editedItem.last_category_id,
                        category_id: this.editedItem.sub_category.category_id,
                        possib_reserving_period: this.editItem.possib_reserving_period,

                        //Price Data
                        price: this.editedItem.price,


                        //Address Informations
                        Address: this.editedItem.Address,
                        // images: imag,
                        CurrencyTransfers_dollar_price: this.editedItem.owner.CurrencyTransfers_dollar_price,
                        work_times: this.editedItem.day_to_open,
                        status_id: this.editedItem.status.id,
                        ItemFeatures: this.editedItem.ItemFeatures,

                        address_id: this.xc,
                        //   last_category_id: this.editedItem.last_category_id






                    };


                    this.loading = true;
                    var posturl;
                    // var state;fxz

                    if (this.$route.params.id) {
                        posturl = "/v2/items/update/" + parseInt(this.$route.params.id);
                        this.state = 'update';
                        // if (this.owner.address.length == 1) {
                        //     data['address_id'] = this.owner.address[0].id;

                        // }




                    } else {
                        posturl = "/v2/items/storex";
                        this.state = 'add';
                    }

                    //  var posturl = "/v2/items/store";




                    data,
                    axios({
                            method: 'post',
                            url: posturl,
                            data: data,


                        })
                        .then(response => {

                            var item_id = response.data.item_id
                            /// this.get_single(imag,item_id);





                            EventBus.$emit("additemsnew", true);



                            this.$http({
                                method: 'get',
                                url: 'owner/v2/OwnerInfo',
                                headers: {}
                            }).then(response => {
                                var owner = response.data.data;
                                if (owner.user.status.id !== 1) {
                                    if (this.state == 'update') {

                                        this.$swal('    تم', "   تم تعديل معلومات الحجز",
                                            'success')

                                        this.$router.push({
                                            name: "additem"
                                        })

                                    } else {

                                        // this.$router.push({
                                        //     name: "OwnerItemImage",
                                        //     params: item_id
                                        // })

                                        this.$router.push({
                                            name: "OwnerItemImage",
                                            params: {
                                                id: item_id
                                            }
                                        })

                                        this.$swal(' تم',
                                            " شكراً تم اضافة الحجز سيتم نشر الحجز في المنصه بعد تفعيل الحساب من قبل الاداره ",
                                            'success')
                                    }

                                } else {
                                    if (this.state == 'update') {

                                        this.$router.push({
                                            name: "additem"

                                        })
                                        this.$swal('    تم', "   تم تعديل معلومات الحجز",
                                            'success')

                                    } else {
                                        this.$router.push({
                                            name: "OwnerItemImage",
                                            params: {
                                                id: item_id
                                            }
                                        })
                                        this.$swal('تم', "تم اضافة الحجز", 'success')
                                    }

                                }

                            })




                            this.$emit("additemsnew", false);


                            this.dialog = false;
                            EventBus.$emit("changeStatus", false);


                            if (response.data.success == true) {

                                this.dialog = false;


                            }




                        })
                        .catch((error) => {
                            error

                            if (this.$route.params.id) {
                                this.$swal('    ', "   فشل تعديل معلومات الحجز",
                                    'error')

                            } else {
                                this.$swal('', "فشل اضافة الحجز", 'error')
                            }


                            // this.$swal('خطاء', this.item_id != 0 ? "     فشل  اضافة الحجز" : "فشل تعديل الحجز",
                            //     'error')

                        }).finally(d => {
                            d,
                            this.loading = false;
                            this.everyday = false;
                            this.everytime = false;
                        });
                    // } else {

                    //     this.err_show = true;




                    //  }





                }




            },



            getCategory() {


                this.$http({
                        method: 'get',
                        url: "/v2/info/Categories",
                    })

                    .then(response => {
                        this.category = response.data.data;



                    })
            },


            getCountry() {


                this.$http({
                        method: 'get',
                        url: "/v2/info/Countries",
                    })

                    .then(response => {
                        this.countries = response.data.data;



                    })
            },



            getSubCategory() {

                // this.lastscategory = false;
                // if (this.item_id == 0) {

                //     var cat_id = this.editedItem.sub_category.category_id
                //     this.editedItem.sub_category.id = this.owner.owner_category.category_id;

                // } else {

                //     cat_id = this.editedItem.sub_category.category_id;
                //     this.editedItem.sub_category.id = this.owner.owner_category.category_id;

                // }





            },
            getProvince() {


                this.$http({
                        method: 'get',
                        url: '/v2/info/Provinces',
                    })

                    .then(response => {
                        this.province = response.data.data;



                    })
            },









            pickFile(index) {

                this.img_cliced = index
                this.$refs.image.click()
            },

            onFilePicked(e) {

                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                        var imgg = {
                            images: [fr.result]
                        };
                        axios({
                                method: 'post',

                                url: 'v2/items/UploudeImage/642',
                                data: imgg
                            })
                            .then(response => {
                                response

                            })

                        this.images[this.img_cliced] = fr.result;
                        var a = this.img_cliced + 1;
                        this.img_name = "   صورة رقم " + a


                    })

                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },

            change_payment(status) {
                if (status == true) {
                    this.editedItem.price.free_cancellation = false;
                    this.editedItem.price.deduction = 0;
                }





            },
            change_reservation_type() {


                if (this.editeDayOpen.reservation_type.id == 2) {
                    this.reservation_num = true;
                } else {
                    this.reservation_num = false;
                    this.reservation_time = true;

                }

            },






            save() {
                if (this.editedIndex > -1) {
                    Object.assign(this.editedItem.day_to_open[this.editedIndex], this.editedItem)
                } else {
                    this.editedItem.day_to_open.push(this.editedItem)
                }
                this.close()
            },


        },

        watch: {
            selected: 'dd',
            steps(val) {

                if (this.e1 > val) {
                    this.e1 = val
                }
            },



        },
        computed: {

            // rules() {
            //   //  alert(this.editedItem.address_id.length);
            //     return [
            //         this.editedItem.address_id.length > 0 || "At least one item should be selected"
            //     ];
            // },

        },




        created() {

            EventBus.$on('getmyLocation', (c, f) => {
                c,
                f
            });

        },


        mounted() {

            this.user_info = this.$cookies.get('admin_info');
            this.item_id = this.item_ID;
            this.GetOwnerInfo();
            this.getCategory();
            this.getSubCategory();
            this.getProvince();

            if (this.$cookies.get('last_owner_item') == null && this.$route.params.id == undefined) {


                this.day_to_open.length = 0;
                this.map = true;


            } else if (this.$route.params.id) {

                this.map = true;
                this.getItemDetails()

            } else {
                this.map = true;

                this.getItemDetails()
                //this.images=[];




            }

            //this.item_id != 0 ? this.getItemDetails() : this.day_to_open.length = 0;

        },

        components: {

            GoogleMap

        }

    }
</script>